import { NgModule } from '@angular/core';

import { AppModule } from './app.module';
import { AppComponent } from './app.component';
import { GtmModule } from './gtm.module';
import { PacketaModule } from './packeta.module';
import { sentryErrorHandlerProvider } from './core/providers/sentry-error-handler-provider';
import { facebookPixelInitializerProvider } from './core/providers/facebook-pixel-initializer-provider';
import { cookieScriptInitializerProvider } from './core/providers/cookie-script-initializer-provider';

@NgModule({
  imports: [AppModule, GtmModule, PacketaModule], // ensure GTM to start in browser only
  providers: [
    cookieScriptInitializerProvider,
    sentryErrorHandlerProvider,
    facebookPixelInitializerProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
